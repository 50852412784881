import React from 'react';
import { NavLink } from 'react-router-dom';

// Images
const heroImage = "https://images.unsplash.com/photo-1561604637-7b58375bafc2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGVkdWNhdGlvbnxlbnwwfHx8fDE2ODQwMzc0NzI&ixlib=rb-1.2.1&q=80&w=1080";
const backgroundImage = "https://images.unsplash.com/photo-1581093793505-831c3d7eae1b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGVkdWNhdGlvbnxlbnwwfHx8fDE2ODQwMzc0NzI&ixlib=rb-1.2.1&q=80&w=1080";
const homeTuitionImage = "hometuition.png";
const liveClassesImage = "https://images.unsplash.com/photo-1505751176933-267c12b5f15f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDExfHxsaXZlJTIwY2xhc3Nlc3xlbnwwfHx8fDE2ODQwODUyNzI&ixlib=rb-1.2.1&q=80&w=1080";
const prepCoursesImage = "https://images.unsplash.com/photo-1519125323398-27583c3d1b8e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDExfHxwZXJzb25hbC1jb3Vyc2VzfGVufDF8fHx8MTY4NDA4NTk2Ng&ixlib=rb-1.2.1&q=80&w=1080";

// Marquee items
const marqueeItems = ["CBSE", "ICSE", "IGCSE", "IB BOARD", "SAT" , "IELTS" , "TOEFL"];

export const HeroSection = () => {
    return (
        <div className="relative overflow-hidden bg-gray-100">

            {/* Hero Section */}
            <div className='relative flex flex-col md:flex-row items-center justify-between px-4 md:px-16 py-10 md:py-20'>
                <div className='w-full md:w-1/2 flex flex-col items-center md:items-start'>
                    <h1 className='text-3xl md:text-5xl font-extrabold text-teal-900 mb-4 md:mb-6 uppercase leading-tight'>
                        Elevate Your Learning Experience
                    </h1>

                    <p className='text-base md:text-lg text-gray-800 mb-6 md:mb-8 max-w-lg'>
                        Beyond Scool provides personalized home tuition, live interactive classes, and a range of tailored courses for students from 4th to 12th grade. We also offer specialized SAT, IELTS, and TOEFL preparation courses to help your child excel academically and achieve their goals.
                    </p>

                    <NavLink
                        to="/contact"
                        className="bg-teal-600 hover:bg-teal-700 text-white rounded-lg md:rounded-2xl border-4 border-teal-600 shadow-lg transition-transform duration-300 transform hover:scale-105 flex items-center justify-center gap-2 py-2 px-4 md:py-3 md:px-6"
                    >
                        <img src="/path/to/getCallIcon.png" alt="Get Call Icon" className='w-5 h-5 md:w-6 md:h-6' />
                        <span className='text-base md:text-lg font-bold'>
                            Schedule a Consultation
                        </span>
                    </NavLink>
                </div>

                <div className='w-full md:w-1/2 mt-8 md:mt-0 flex justify-center'>
                    <img src={heroImage} alt="Students Learning" className='w-full md:w-3/4 rounded-lg shadow-lg' />
                </div>
            </div>

            {/* Highlight Services */}
            <div className='py-10 md:py-20 px-4 md:px-16 grid grid-cols-1 md:grid-cols-3 gap-6'>
                <div className='bg-white p-6 rounded-lg shadow-md text-center'>
                    <img src={homeTuitionImage} alt="Home Tuition" className='w-full h-40 object-cover rounded-md mb-4' />
                    <h3 className='text-xl font-semibold text-teal-900 mb-2'>Home Tuition</h3>
                    <p className='text-gray-700'>
                        Tailored home tuition for grades 4 to 12. Our experienced tutors provide one-on-one support to cater to individual learning needs.
                    </p>
                </div>
                <div className='bg-white p-6 rounded-lg shadow-md text-center'>
                    <img src={liveClassesImage} alt="Live Interactive Classes" className='w-full h-40 object-cover rounded-md mb-4' />
                    <h3 className='text-xl font-semibold text-teal-900 mb-2'>Live Interactive Classes</h3>
                    <p className='text-gray-700'>
                        Engage in real-time learning with live classes that connect students to expert educators, fostering an interactive educational experience.
                    </p>
                </div>
                <div className='bg-white p-6 rounded-lg shadow-md text-center'>
                    <img src={prepCoursesImage} alt="SAT, IELTS, and TOEFL Preparation" className='w-full h-40 object-cover rounded-md mb-4' />
                    <h3 className='text-xl font-semibold text-teal-900 mb-2'>SAT, IELTS, and TOEFL Courses</h3>
                    <p className='text-gray-700'>
                        Comprehensive preparation courses for SAT, IELTS, and TOEFL to boost your scores and help you succeed in your academic endeavors.
                    </p>
                </div>
            </div>

            {/* Marquee */}
            <div className="bg-teal-100 py-4">
                <div className="flex justify-center">
                    <marquee className="text-lg md:text-2xl font-semibold text-teal-900 py-2" behavior="scroll" direction="left" scrollamount="8">
                        {marqueeItems.map((item, index) => (
                            <span key={index}>
                                {index > 0 && <span className="mx-4 text-gray-600">&#8226;</span>}
                                {item}
                            </span>
                        ))}
                    </marquee>
                </div>
            </div>

            {/* Hero Section 2 */}
            <div className='relative p-4 md:p-16 text-center md:text-left bg-cover bg-center' style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className='bg-gray-900 bg-opacity-70 p-8 md:p-12 rounded-lg'>
                    <h2 className='text-2xl md:text-4xl font-extrabold text-white mb-4'>
                        Welcome to Beyond Scool
                    </h2>
                    <p className='text-base md:text-lg text-gray-200 leading-relaxed'>
                        At Beyond Scool, we are dedicated to transforming education through personalized learning solutions. Our offerings include home tuition, live interactive classes, and a variety of specialized courses, including SAT, IELTS, and TOEFL preparation. Discover how we can help you achieve your educational goals with our innovative and flexible learning options.
                    </p>
                </div>
            </div>
        </div>
    );
}
