import React from 'react';
import { images } from '../../../Constants';

const testimonials = [
    {
        name: "Shruti Garg",
        imgUrl: images.logo,
        body: "Enrolling in your math and science courses was a game-changer for me! Your engaging teaching style and comprehensive curriculum helped me grasp complex concepts with ease. Thanks to your guidance, I not only aced my exams but also developed a newfound love for these subjects."
    },
    {
        name: "Bhavya Soni",
        imgUrl: images.logo,
        body: "Your SAT prep course was exactly what I needed to boost my scores and secure admission to my dream college. The personalized attention and strategic approach to test-taking strategies made all the difference. Thank you for equipping me with the skills and confidence to excel!"
    },
    {
        name: "Mohan Sharma",
        imgUrl: images.logo,
        body: "I can't thank you enough for the invaluable knowledge and support I received in your courses. Your dedication to helping students succeed in math and science is truly commendable. From clarifying doubts to providing additional resources, you went above and beyond to ensure our success."
    },
    {
        name: "Laksh Raj",
        imgUrl: images.logo,
        body: "Your math classes were a lifesaver for me! Your patient explanations and practical examples made even the most challenging topics seem manageable. I'm grateful for your commitment to helping students like me overcome academic hurdles and reach our full potential."
    },
    {
        name: "Harshita Jain",
        imgUrl: images.logo,
        body: "Your science courses were nothing short of transformative! Your passion for the subject shone through in every lesson, inspiring me to delve deeper into the intricacies of the natural world. Thanks to your guidance, I developed a deeper understanding and appreciation for science."
    },
    {
        name: "Mohit Kumar",
        imgUrl: images.logo,
        body: "Attending your courses was one of the best decisions I've made for my academic journey. Your well-structured lessons and attention to detail ensured that I not only mastered the content but also developed critical thinking skills. Thank you for empowering me to succeed in math, science, and beyond!"
    },
];

export const Testimonial = () => {
    return (
        <div className='py-10 px-4 md:px-8' style={{ backgroundColor: '#F7F9FC' }}>
            <h2 className='text-2xl md:text-3xl font-bold text-center text-gray-800 mb-8'>
                What Our Students Say
            </h2>

            <div className='flex flex-wrap justify-center gap-6'>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className='bg-white p-4 md:p-6 rounded-lg shadow-md flex flex-col items-center max-w-xs transition-transform transform hover:scale-105'>
                        <div className='w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden mb-4'>
                            <img src={testimonial.imgUrl} alt={testimonial.name} className='w-full h-full object-cover' />
                        </div>
                        <p className='text-base md:text-lg text-gray-700 italic mb-3 text-center'>
                            “{testimonial.body}”
                        </p>
                        <h3 className='text-sm md:text-md font-semibold text-gray-800'>
                            {testimonial.name}
                        </h3>
                    </div>
                ))}
            </div>
        </div>
    );
}
