import React from 'react';
import { images } from '../../../Constants';

const offering = [
    {
        imgUrl: images.faq1,
        offer: "Courses"
    },
    {
        imgUrl: images.live,
        offer: "Live Classes"
    },
    {
        imgUrl: images.faq2,
        offer: "Live Doubt Support"
    },
    {
        imgUrl: images.faq3,
        offer: "Specialized Programs"
    }
]

export const Offering = () => {
    return (
        <div className='py-14 px-4 md:px-16' style={{ backgroundColor: '#CCFBF1' }}>
            <h2 className='text-3xl sm:text-4xl font-bold text-center text-gray-800 mb-12'>
                Our Offerings
            </h2>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12'>
                {offering.map((data, index) => (
                    <div key={index} className='bg-white p-6 rounded-lg shadow-lg flex flex-col items-center transition-transform duration-300 ease-in-out hover:scale-105'>
                        <div className='w-20 h-20 mb-4'>
                            <img src={data.imgUrl} alt={data.offer} className='w-full h-full object-contain' />
                        </div>
                        <div className='text-lg sm:text-xl font-semibold text-gray-800 uppercase'>
                            {data.offer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

