import React, { useState, useEffect } from 'react';
import { images } from '../../../Constants';

const curriculum = [
    { 
        name: "Comprehensive Curriculum", 
        content: "Our curriculum covers a broad range of subjects designed to meet diverse learning needs and support academic and professional success." 
    },
    { 
        name: "Interactive Learning", 
        content: "We employ engaging methods to make learning effective and enjoyable, ensuring deeper understanding and retention of material." 
    },
    { 
        name: "Expert Instruction", 
        content: "Learn from industry experts with courses that provide high-quality education across various fields and levels." 
    },
    { 
        name: "Community Engagement", 
        content: "Become part of a supportive community that fosters collaboration, networking, and mutual growth among learners." 
    },
    { 
        name: "Flexible Learning Options", 
        content: "Our flexible curriculum adapts to individual schedules and learning styles, accommodating different needs and preferences." 
    }
];

export const Curriculum = () => {
    const [selectedContent, setSelectedContent] = useState(curriculum[0].content);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = (activeIndex + 1) % curriculum.length;
            setSelectedContent(curriculum[nextIndex].content);
            setActiveIndex(nextIndex);
        }, 5000);
        return () => clearInterval(interval);
    }, [activeIndex]);

    const handleLiClick = (content, index) => {
        setSelectedContent(content);
        setActiveIndex(index);
    };

    return (
        <div className='py-8 px-4 sm:px-8' style={{ backgroundColor: '#CCFBF1' }}>
            <div className='container mx-auto flex flex-col md:flex-row items-start md:items-center gap-6'>
                {/* Image Section */}
                <div className='hidden md:block flex-1'>
                    <img src={images.educationImage} alt="Education" className='w-full h-auto rounded-lg shadow-md' />
                </div>

                {/* Text Section */}
                <div className='flex-1'>
                    <h2 className='text-2xl sm:text-3xl font-bold text-gray-800 text-center mb-4 md:mb-6'>
                        Discover Our Unique Curriculum
                    </h2>
                    <div className='flex flex-col md:flex-row'>
                        <div className='flex-1 mb-4 md:hidden text-base sm:text-lg text-gray-700'>
                            {selectedContent}
                        </div>
                        <div className='flex-1'>
                            <ul className='space-y-4'>
                                {curriculum.map((data, index) => (
                                    <React.Fragment key={index}>
                                        <li
                                            className={`py-3 px-4 cursor-pointer text-base sm:text-lg font-semibold transition-transform duration-300 ease-in-out ${activeIndex === index ? 'bg-yellow-300 text-gray-900 rounded-lg shadow-md' : 'bg-white text-gray-800 border border-gray-300'}`}
                                            onClick={() => handleLiClick(data.content, index)}
                                        >
                                            {data.name}
                                        </li>
                                        {index < curriculum.length - 1 && (
                                            <hr className='border border-gray-300' />
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                        <div className='hidden md:flex flex-1 text-base sm:text-lg text-gray-700'>
                            {selectedContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
