import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import { images } from '../../../Constants';

const faq = [
    {
        topic: "What subjects do you provide home tuition for?",
        description: "We offer personalized home tuition for a wide range of subjects including Mathematics, Science, Physics, and English."
    },
    {
        topic: "Which grade levels do you support?",
        description: "Our home tuition services are available for students from 4th grade to 12th grade, covering elementary through high school."
    },
    {
        topic: "What types of classes are available?",
        description: "We provide one-to-one home tuition and live interactive classes. Additionally, we offer a variety of personalized courses tailored to individual needs."
    },
    {
        topic: "Do you offer preparation for standardized tests?",
        description: "Yes, we offer comprehensive preparation for SAT, IELTS, and TOEFL exams. Our courses are designed to equip students with the skills and confidence needed for these tests."
    },
    {
        topic: "How do you personalize lesson plans?",
        description: "Our lesson plans are customized based on each student's unique learning style and academic needs, ensuring effective and targeted learning."
    },
    {
        topic: "What are the scheduling options for classes?",
        description: "Classes can be scheduled at flexible times to suit students' availability. We offer both live interactive classes and on-demand courses that can be accessed anytime."
    },
    {
        topic: "How are classes conducted?",
        description: "Classes are conducted online using interactive tools to enhance learning. We also offer a free demo class to help students get acquainted with our teaching methods."
    },
    {
        topic: "How can I contact customer support?",
        description: "For any queries or support, you can reach us via live chat, email (beyondscool@gmail.com), or phone (9972148403). We’re here to assist you!"
    },
    {
        topic: "Do you offer free trial sessions?",
        description: "Yes, we offer 3 free demo classes for new students to experience our teaching methods and see if it’s a good fit."
    },
];

export const FAQ = () => {
    const [showDescription, setShowDescription] = useState(new Array(faq.length).fill(false));

    const toggleDescription = (index) => {
        const newShowDescription = [...showDescription];
        newShowDescription[index] = !newShowDescription[index];
        setShowDescription(newShowDescription);
    };

    return (
        <div className='py-16 px-4 sm:px-8 bg-gray-50'>
            <div className='flex flex-col sm:flex-row items-center sm:items-start justify-between mb-12'>
                <h2 className='text-3xl sm:text-5xl font-bold text-teal-800 uppercase mb-6 sm:mb-0'>
                    Frequently Asked Questions
                </h2>
                <div className='w-24 h-24 sm:w-36 sm:h-36'>
                    <img src={images.faqImage} alt="FAQ" className='w-full h-full object-cover rounded-full shadow-lg' />
                </div>
            </div>

            <div className='space-y-8'>
                {faq.map((item, index) => (
                    <div key={index} className='bg-white p-6 rounded-lg shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105'>
                        <div className='flex justify-between items-center cursor-pointer'>
                            <p className='text-lg sm:text-xl font-semibold text-gray-900' onClick={() => toggleDescription(index)}>
                                {item.topic}
                            </p>
                            <i 
                                className={`fa-solid fa-chevron-down text-teal-600 transition-transform duration-300 ${showDescription[index] ? 'rotate-180' : ''}`} 
                                onClick={() => toggleDescription(index)}
                            ></i>
                        </div>
                        {showDescription[index] && (
                            <div className='mt-4 text-gray-800'>
                                {item.description}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

