import React from 'react';
import { images } from '../../Constants';
import { NavLink } from 'react-router-dom';
import { FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'; // For social media icons

const socialLinks = [
  { imgUrl: <FaInstagram size={24} color="#FF007A" />, link: "https://www.instagram.com/beyondscool_?igsh=ZmhjMXJsbzUxdTdj" },
  { imgUrl: <FaLinkedin size={24} color="#0A66C2" />, link: "https://www.linkedin.com/company/beyondscool/?viewAsMember=true" },
  { imgUrl: <FaYoutube size={24} color="#FF0000" />, link: "https://www.youtube.com/channel/UC2MASX0K6Tn3oikYcnMORMA" },
];

const courses = [
  { name: "SAT Preparation", link: "/sat-courses" },
  { name: "ACT Preparation", link: "/act-courses" },
];

const batches = [
  { name: "IELTS", link: "/ielts" },
  { name: "TOEFL", link: "/toefl" },
  { name: "Home Tuition", link: "/home-tuition" },
];

const quickLinks = [
  { name: "HOME", link: "/" },
  { name: "ABOUT US", link: "/about" },
  { name: "COURSES", link: "/courses" },
  { name: "CONTACT US", link: "/contact" },
  { name: "PRIVACY POLICY", link: "/privacypolicy" },
  { name: "TERMS & CONDITIONS", link: "/terms&conditions" },
  { name: "REFUND POLICY", link: "/refundpolicy" },
];

const contactUs = [
  { name: "+91 9972148403​", link: "tel:+91 9972148403​", imgUrl: images.telephone },
  { name: "+91 9319339686", link: "tel:+91 9319339686", imgUrl: images.telephone },
  { name: "team@beyondscool.com", link: "mailto:team@beyondscool.com", imgUrl: images.gmail },
  { name: "Bommanahalli, Kodichikknahalli, Bangalore - 560076", link: "https://www.google.com/maps/place/Kodichikkanahalli+Main+Rd,+Seenappa+Layout,+Bommanahalli,+Bengaluru,+Karnataka/@12.8976497,77.6141846,20z/data=!4m6!3m5!1s0x3bae14ddddd53d79:0x507ae18c6090fa7c!8m2!3d12.8981034!4d77.618035!16s%2Fg%2F1tdvc053?entry=tts", imgUrl: images.location },
];

export const Footer = () => {
  return (
    <footer className='bg-[#CCFBF1] text-gray-800 py-10 px-6 md:px-16'>
      <div className='container mx-auto'>
        <div className='flex flex-col md:flex-row justify-between items-start md:items-center'>
          {/* Logo and Description */}
          <div className='flex flex-col items-center md:items-start'>
            <NavLink to="/" className="flex items-center gap-2 md:gap-4">
              <img src={images.logo} alt='BeyondScool Logo' className='w-16 h-16 md:w-24 md:h-24' />
              <div className='text-3xl md:text-4xl font-extrabold text-gray-800 italic'>
                BeyondScool
              </div>
            </NavLink>
            <p className='text-center md:text-left text-lg mt-4'>
              BeyondScool personalizes your learning experience through a wide range of courses and live batches.
            </p>
          </div>

          {/* Social Media Links */}
          <div className='flex gap-4 mt-6 md:mt-0'>
            {socialLinks.map((data, index) => (
              <a key={index} href={data.link} target="_blank" rel="noopener noreferrer" className='hover:text-blue-600 transition-colors'>
                {data.imgUrl}
              </a>
            ))}
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-between mt-8'>
          {/* Courses Links */}
          <div className='flex flex-col mb-8 md:mb-0'>
            <h2 className='text-2xl font-semibold uppercase mb-4 text-gray-800'>Courses</h2>
            <ul className='space-y-2'>
              {courses.map((data, index) => (
                <li key={index} className='text-lg'><NavLink to={data.link} className='hover:text-blue-600'>{data.name}</NavLink></li>
              ))}
            </ul>
          </div>

          {/* Batches Links */}
          <div className='flex flex-col mb-8 md:mb-0'>
            <h2 className='text-2xl font-semibold uppercase mb-4 text-gray-800'>Batches</h2>
            <ul className='space-y-2'>
              {batches.map((data, index) => (
                <li key={index} className='text-lg'><NavLink to={data.link} className='hover:text-blue-600'>{data.name}</NavLink></li>
              ))}
            </ul>
          </div>

          {/* Quick Links */}
          <div className='flex flex-col mb-8 md:mb-0'>
            <h2 className='text-2xl font-semibold uppercase mb-4 text-gray-800'>Quick Links</h2>
            <ul className='space-y-2'>
              {quickLinks.map((data, index) => (
                <li key={index} className='text-lg'><NavLink to={data.link} className='hover:text-blue-600'>{data.name}</NavLink></li>
              ))}
            </ul>
          </div>

          {/* Contact Us */}
          <div className='flex flex-col'>
            <h2 className='text-2xl font-semibold uppercase mb-4 text-gray-800'>Contact Us</h2>
            <div className='space-y-4'>
              {contactUs.map((data, index) => (
                <div key={index} className='flex items-center space-x-4'>
                  <img src={data.imgUrl} alt={data.name} className='w-6 h-6' />
                  <a href={data.link} className='text-lg hover:text-blue-600'>{data.name}</a>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <div className='relative w-full h-60'>
            <iframe
              title="Location"
              className='absolute top-0 left-0 w-full h-full border-0 rounded-lg'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d778045.8982790374!2d77.47615923421415!3d12.8905977752074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c2551299%3A0x18f1ac7e3b3101a7!2s12.89730853567345%2C%2077.61438716959002!5e0!3m2!1sen!2sus!4v1621359849306!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>

      <div className='text-center text-sm mt-8'>
        <hr className='border-gray-300 mb-4' />
        <p>&copy; 2024 BeyondScool. All Rights Reserved. <NavLink to="/privacypolicy" className='text-blue-600 hover:underline'>Privacy Policy</NavLink></p>
      </div>
    </footer>
  );
};

