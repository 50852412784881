import React, { useState, useEffect } from 'react';

const services = [
    { name: "Personalized Tutoring", content: "Our personalized tutoring sessions cater to individual learning needs, offering tailored support to help students excel in their studies. Our experienced tutors adapt their teaching methods to suit each student's unique learning style." },
    { name: "Interactive Online Courses", content: "We offer a wide range of interactive online courses designed to engage students and enhance learning. These courses include multimedia content, quizzes, and interactive exercises to reinforce concepts." },
    { name: "Expert Instructors", content: "Our team of expert instructors comprises professionals and educators with extensive experience in their fields. They provide high-quality instruction and mentorship to help students achieve their academic and career goals." },
    { name: "Flexible Scheduling", content: "With flexible scheduling options, students can choose class timings that fit their personal schedules. This flexibility ensures that learning can continue without interrupting daily activities." },
    { name: "Comprehensive Support", content: "We provide comprehensive support, including academic guidance, career counseling, and access to additional resources. Our goal is to support students throughout their learning journey." }
];

export const About = () => {
    const [selectedContent, setSelectedContent] = useState(services[0].content);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = (activeIndex + 1) % services.length;
            setSelectedContent(services[nextIndex].content);
            setActiveIndex(nextIndex);
        }, 5000);
        return () => clearInterval(interval);
    }, [activeIndex]);

    const handleLiClick = (content, index) => {
        setSelectedContent(content);
        setActiveIndex(index);
    };

    return (
        <div className='bg-[#CCFBF1] p-6 md:p-12'>
            {/* Introduction Section */}
            <div className='text-center mb-12'>
                <h2 className='text-3xl md:text-5xl font-bold text-gray-800'>Welcome to Beyond Scool</h2>
                <p className='text-sm md:text-lg font-medium text-gray-700 mt-4'>
                    At BeyondScool, we are committed to redefining the education experience through innovative technology and personalized learning solutions. We offer a range of services designed to support learners of all ages and backgrounds, ensuring they have the tools and resources they need to succeed.
                </p>
            </div>

            {/* Our Services Section */}
            <div className='max-w-4xl mx-auto'>
                <h2 className='text-2xl md:text-4xl font-semibold text-gray-800 text-center mb-8'>Our Services</h2>
                <div className='flex flex-col md:flex-row'>
                    {/* Services List */}
                    <ul className='w-full md:w-1/3 space-y-4'>
                        {services.map((service, index) => (
                            <React.Fragment key={index}>
                                <li
                                    className={`cursor-pointer text-xl md:text-2xl font-semibold transition-transform duration-300 ${activeIndex === index ? 'text-[#1E40AF] transform scale-105' : 'text-gray-700'}`}
                                    onClick={() => handleLiClick(service.content, index)}
                                >
                                    {service.name}
                                </li>
                            </React.Fragment>
                        ))}
                    </ul>

                    {/* Content Display */}
                    <div className='w-full md:w-2/3 mt-6 md:mt-0 md:pl-6'>
                        <div className='bg-white shadow-lg rounded-lg p-6'>
                            <p className='text-lg md:text-xl text-gray-700'>{selectedContent}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
