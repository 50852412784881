import React, { useEffect, useState } from 'react';
import displayRazorpay from '../../../utils/PaymentGateway';
import { useAuth } from '../../../AuthProvider/AuthProvider';
import { images } from '../../../Constants';

// Updated courses data with specific features and details
const courses = [
    // SAT Courses
    {
        imgUrl: images.SAT_FOUNDATION,
        class: "SAT",
        name: "SAT Live Online",
        subject: "Mathematics & Verbal",
        hours: "18 hours",
        priceinUSD: "$799",
        amount: "63920",
        features: [
            "18 hours of immersive live instruction",
            "Learn from 99th-percentile-scoring SAT teachers",
            "Fully updated for the Digital SAT",
            "2+ teachers per class",
            "Realistic SAT practice tests",
            "Digital performance dashboard"
        ]
    },
    {
        imgUrl: images.SAT_BOOST,
        class: "SAT",
        name: "SAT Bootcamp",
        subject: "Mathematics & Verbal",
        hours: "30 hours",
        priceinUSD: "$999",
        amount: "79920",
        features: [
            "Prep for the SAT in 2-3 weeks",
            "Learn from 99th-percentile-scoring SAT teachers",
            "Fully updated for the Digital SAT",
            "18 hours of live instruction",
            "Private 1:1 Q&A with teachers",
            "Realistic SAT practice tests, including 2 live proctored tests"
        ]
    },
    {
        imgUrl: images.SAT_CORE,
        class: "SAT",
        name: "SAT Tutoring + Course",
        subject: "Mathematics & Verbal",
        hours: "Includes 18-hour Live Online course + 10, 20, 30, or 40 hours of 1-on-1 tutoring",
        priceinUSD: "From $1999",
        amount: "159920",
        features: [
            "10, 20, 30, or 40 hours of private, 1-on-1 tutoring",
            "Personalized study plan from an SAT expert",
            "Includes a complete, 18-hour Live Online SAT course",
            "2 full-length adaptive practice tests with score analysis",
            "Includes practice books and all on-demand lessons"
        ]
    },

    // ACT Courses
    {
        imgUrl: images.ACT_FOUNDATION,
        class: "ACT",
        name: "ACT Live Online",
        subject: "Mathematics & Science",
        hours: "18 hours",
        priceinUSD: "$699",
        amount: "55920",
        features: [
            "18 hours of immersive live instruction",
            "Learn from expert ACT teachers",
            "Fully updated for the ACT",
            "2+ teachers per class",
            "Realistic ACT practice tests",
            "Digital performance dashboard"
        ]
    },
    {
        imgUrl: images.ACT_BOOST,
        class: "ACT",
        name: "ACT Bootcamp",
        subject: "Mathematics & Science",
        hours: "30 hours",
        priceinUSD: "$899",
        amount: "71920",
        features: [
            "Prep for the ACT in 2-3 weeks",
            "Learn from expert ACT teachers",
            "Fully updated for the ACT",
            "18 hours of live instruction",
            "Private 1:1 Q&A with teachers",
            "Realistic ACT practice tests, including 2 live proctored tests"
        ]
    },
    {
        imgUrl: images.ACT_CORE,
        class: "ACT",
        name: "ACT Tutoring + Course",
        subject: "Mathematics & Science",
        hours: "Includes 18-hour Live Online course + 10, 20, 30, or 40 hours of 1-on-1 tutoring",
        priceinUSD: "From $1799",
        amount: "143920",
        features: [
            "10, 20, 30, or 40 hours of private, 1-on-1 tutoring",
            "Personalized study plan from an ACT expert",
            "Includes a complete, 18-hour Live Online ACT course",
            "2 full-length adaptive practice tests with score analysis",
            "Includes practice books and all on-demand lessons"
        ]
    },

    // GRE Courses
    {
        imgUrl: images.GRE_FOUNDATION,
        class: "GRE",
        name: "GRE Live Online",
        subject: "Quantitative & Verbal",
        hours: "18 hours",
        priceinUSD: "$899",
        amount: "71920",
        features: [
            "18 hours of immersive live instruction",
            "Learn from expert GRE teachers",
            "Fully updated for the GRE",
            "2+ teachers per class",
            "Realistic GRE practice tests",
            "Digital performance dashboard"
        ]
    },
    {
        imgUrl: images.GRE_BOOST,
        class: "GRE",
        name: "GRE Bootcamp",
        subject: "Quantitative & Verbal",
        hours: "30 hours",
        priceinUSD: "$1099",
        amount: "87920",
        features: [
            "Prep for the GRE in 2-3 weeks",
            "Learn from expert GRE teachers",
            "Fully updated for the GRE",
            "18 hours of live instruction",
            "Private 1:1 Q&A with teachers",
            "Realistic GRE practice tests, including 2 live proctored tests"
        ]
    },
    {
        imgUrl: images.GRE_CORE,
        class: "GRE",
        name: "GRE Tutoring + Course",
        subject: "Quantitative & Verbal",
        hours: "Includes 18-hour Live Online course + 10, 20, 30, or 40 hours of 1-on-1 tutoring",
        priceinUSD: "From $1399",
        amount: "111920",
        features: [
            "10, 20, 30, or 40 hours of private, 1-on-1 tutoring",
            "Personalized study plan from a GRE expert",
            "Includes a complete, 18-hour Live Online GRE course",
            "2 full-length adaptive practice tests with score analysis",
            "Includes practice books and all on-demand lessons"
        ]
    }
];

export const Courses = () => {
    const { isloggedIn } = useAuth();
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [filteredCourses, setFilteredCourses] = useState([]);

    useEffect(() => {
        let filtered = courses;
        if (selectedClass !== '') {
            filtered = filtered.filter(course => course.class === selectedClass);
        }
        if (selectedSubject !== '') {
            filtered = filtered.filter(course => course.subject === selectedSubject);
        }
        setFilteredCourses(filtered);
    }, [selectedClass, selectedSubject]);

    return (
        <div className='pb-10 px-4 sm:px-8 lg:px-16' style={{ backgroundColor: '#CCFBF1' }}>
            <div className='text-center mb-8'>
                <h2 className='text-3xl md:text-4xl font-semibold mb-4 text-gray-800'>Achieve Your Academic Goals with Our Expert Prep Courses</h2>
                <p className='text-sm md:text-lg text-gray-700'>
                    Choose from our wide range of prep courses to get the best score on your upcoming test. 
                    Our expert-led courses are designed to maximize your performance and ensure your success.
                </p>
            </div>
            
            <div className='flex flex-col md:flex-row md:justify-between gap-4 mb-8'>
                <div className='flex flex-col w-full md:w-1/2'>
                    <label className='text-lg font-semibold mb-2 text-gray-700'>Select Class</label>
                    <select 
                        className='border rounded-lg p-3 mb-4 shadow-md bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-teal-500'
                        value={selectedClass} 
                        onChange={(e) => setSelectedClass(e.target.value)}
                    >
                        <option value="" disabled hidden>Select Class</option>
                        <option value="">All Classes</option>
                        {[...new Set(courses.map(data => data.class))].map((classValue, index) => (
                            <option key={index} value={classValue}>{classValue}</option>
                        ))}
                    </select>
                </div>
                <div className='flex flex-col w-full md:w-1/2'>
                    <label className='text-lg font-semibold mb-2 text-gray-700'>Select Subject</label>
                    <select 
                        className='border rounded-lg p-3 mb-4 shadow-md bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-teal-500'
                        value={selectedSubject} 
                        onChange={(e) => setSelectedSubject(e.target.value)}
                    >
                        <option value="" disabled hidden>Select Subject</option>
                        <option value="">All Subjects</option>
                        {[...new Set(courses.map(data => data.subject))].map((subjectValue, index) => (
                            <option key={index} value={subjectValue}>{subjectValue}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
                {filteredCourses.length > 0 ? filteredCourses.map((data, index) => (
                    <div 
                        className='bg-white border border-gray-300 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105'
                        key={index}
                    >
                        <div className="relative">
                            <img 
                                className='object-cover w-full h-40 md:h-48' 
                                src={data.imgUrl || ''} 
                                alt={`${data.name} Thumbnail`} 
                            />
                            <div className='absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-20'></div>
                        </div>
                        <div className='p-6'>
                            <h2 className='text-xl font-bold mb-2 text-gray-800'>{data.name}</h2>
                            <ul className='space-y-2 text-sm text-gray-600'>
                                <li><strong>Subject:</strong> {data.subject}</li>
                                <li><strong>Hours:</strong> {data.hours}</li>
                                <li><strong>Price (USD):</strong> {data.priceinUSD}</li>
                            </ul>
                            <div className='mt-4'>
                                <h3 className='text-lg font-semibold text-gray-800 mb-2'>Features:</h3>
                                <ul className='list-disc list-inside text-sm text-gray-600'>
                                    {data.features.map((feature, i) => (
                                        <li key={i} className='mb-1'>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='flex flex-col items-center p-6 border-t border-gray-300'>
                            <div className='flex flex-col items-center'>
                                <span className='text-lg font-semibold text-gray-500 line-through'>₹{data.amount}</span>
                                <strong className='text-2xl font-bold text-gray-800'>₹{data.amount}</strong>
                            </div>
                            <button 
                                onClick={() => displayRazorpay(data.amount, data.class, data.subject, isloggedIn)} 
                                className='bg-[#5960FF] text-white text-lg font-bold px-4 py-2 rounded-lg mt-4 hover:bg-[#4347e0] transition'
                            >
                                Enroll Now
                            </button>
                        </div>
                    </div>
                )) : <p className='text-center text-gray-600'>No courses available at the moment</p>}
            </div>
        </div>
    );
};

