import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import displayRazorpay from '../../../utils/PaymentGateway';
import { images } from '../../../Constants';

const batches = [
    // Existing batches (excluding SAT Prep and live one-to-one classes)
    {
        imgUrl: images.ieltsThumbnail,
        name: "IELTS Prep",
        class: "Beginner",
        subject: "IELTS",
        classSize: "8-12 students",
        liveClass: "3 days a week",
        amount: 5999
    },
    {
        imgUrl: images.toeflThumbnail,
        name: "TOEFL Prep",
        class: "Beginner",
        subject: "TOEFL",
        classSize: "10-15 students",
        liveClass: "4 days a week",
        amount: 6499
    },
    {
        imgUrl: images.toeflThumbnail,
        name: "Home Tuition",
        class: "Beginner",
        subject: "Any Subject",
        amount: 10000
    },
    
    // New batches for Class 9 and 10 CBSE
    {
        imgUrl: images.mathsThumbnail, // Replace with appropriate image URL
        name: "Class 9 CBSE Maths",
        class: "Class 9",
        subject: "Maths",
        classSize: "10-15 students",
        liveClass: "3 days a week",
        amount: 4999
    },
    {
        imgUrl: images.scienceThumbnail, // Replace with appropriate image URL
        name: "Class 9 CBSE Science",
        class: "Class 9",
        subject: "Science",
        classSize: "10-15 students",
        liveClass: "3 days a week",
        amount: 4999
    },
    {
        imgUrl: images.mathsThumbnail, // Replace with appropriate image URL
        name: "Class 10 CBSE Maths",
        class: "Class 10",
        subject: "Maths",
        classSize: "10-15 students",
        liveClass: "3 days a week",
        amount: 5499
    },
    {
        imgUrl: images.scienceThumbnail, // Replace with appropriate image URL
        name: "Class 10 CBSE Science",
        class: "Class 10",
        subject: "Science",
        classSize: "10-15 students",
        liveClass: "3 days a week",
        amount: 5499
    },

    // New combined batches for Math and Science
    {
        imgUrl: images.mathsThumbnail, // Replace with appropriate image URL
        name: "Class 9 CBSE Maths & Science",
        class: "Class 9",
        subject: "Maths & Science",
        classSize: "10-15 students",
        liveClass: "4 days a week",
        amount: 8999
    },
    {
        imgUrl: images.mathsThumbnail, // Replace with appropriate image URL
        name: "Class 10 CBSE Maths & Science",
        class: "Class 10",
        subject: "Maths & Science",
        classSize: "10-15 students",
        liveClass: "4 days a week",
        amount: 9499
    }
];

export const Batches = () => {
    const [selectedBatches, setSelectedBatches] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [filteredBatches, setFilteredBatches] = useState([]);

    useEffect(() => {
        let filtered = batches;

        if (selectedBatches !== '') {
            filtered = filtered.filter(batch => batch.class === selectedBatches);
        }
        if (selectedSubject !== '') {
            filtered = filtered.filter(batch => batch.subject === selectedSubject);
        }
        setFilteredBatches(filtered);
    }, [selectedBatches, selectedSubject]);

    return (
        <div className='pb-10 px-4 sm:px-8 lg:px-16' style={{ backgroundColor: '#CCFBF1' }}>
            <div className='flex flex-col md:flex-row md:justify-between gap-6 mb-8'>
                <div className='flex flex-col w-full md:w-1/2'>
                    <label className='text-lg font-semibold mb-2 text-gray-700'>Select Class</label>
                    <select 
                        className='border rounded-lg p-3 mb-4 shadow-md bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-teal-500'
                        value={selectedBatches} 
                        onChange={(e) => setSelectedBatches(e.target.value)}
                    >
                        <option value="" disabled hidden>Select Class</option>
                        <option value="">All Classes</option>
                        {[...new Set(batches.map(data => data.class))].map((classValue, index) => (
                            <option key={index} value={classValue}>{classValue}</option>
                        ))}
                    </select>
                </div>
                <div className='flex flex-col w-full md:w-1/2'>
                    <label className='text-lg font-semibold mb-2 text-gray-700'>Select Subject</label>
                    <select 
                        className='border rounded-lg p-3 mb-4 shadow-md bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-teal-500'
                        value={selectedSubject} 
                        onChange={(e) => setSelectedSubject(e.target.value)}
                    >
                        <option value="" disabled hidden>Select Subject</option>
                        <option value="">All Subjects</option>
                        {[...new Set(batches.map(data => data.subject))].map((subjectValue, index) => (
                            <option key={index} value={subjectValue}>{subjectValue}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
                {filteredBatches.length > 0 ? filteredBatches.map((data, index) => {
                    // Calculate discounted price
                    const originalPrice = data.amount;
                    const discount = 0.20; // 20% discount
                    const discountedPrice = originalPrice - (originalPrice * discount);

                    return (
                        <div 
                            className='bg-white border border-gray-300 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105'
                            key={index}
                        >
                            <div className="relative">
                                <img 
                                    className='object-cover w-full h-40' 
                                    src={data.imgUrl || ''} 
                                    alt={`${data.name} Thumbnail`} 
                                />
                                <div className='absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-25'></div>
                            </div>
                            <div className='p-6'>
                                <h2 className='text-xl font-bold mb-2 text-gray-800'>{data.name}</h2>
                                <ul className='space-y-2 text-sm text-gray-600'>
                                    <li><strong>Class:</strong> {data.class}</li>
                                    <li><strong>Subject:</strong> {data.subject}</li>
                                    {data.classSize && <li><strong>Class Size:</strong> {data.classSize}</li>}
                                    {data.liveClass && <li><strong>Live Classes:</strong> {data.liveClass}</li>}
                                </ul>
                            </div>
                            <div className='flex flex-col items-center p-6 border-t border-gray-300'>
                                <div className='flex flex-col items-center'>
                                    <span className='text-lg font-semibold text-gray-500 line-through'>₹{originalPrice}</span>
                                    <strong className='text-2xl font-bold text-gray-800'>₹{discountedPrice.toFixed(2)}</strong>
                                </div>
                                <button 
                                    onClick={() => displayRazorpay(discountedPrice, data.class, data.subject)} 
                                    className='bg-[#5960FF] text-white text-lg font-bold px-4 py-2 rounded-lg mt-4 hover:bg-[#4347e0] transition'
                                >
                                    Enroll Now
                                </button>
                            </div>
                        </div>
                    );
                }) : <p className='text-lg text-gray-700'>No courses available at the moment</p>}
            </div>
        </div>
    );
}
