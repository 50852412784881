import React from 'react';
import { NavLink } from 'react-router-dom';
import { images } from '../../../Constants';
import { useAuth } from '../../../AuthProvider/AuthProvider';

const Menus = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Courses", path: "/courses" },
    { name: "Contact Us", path: "/contact" }
];

const dropDown = [
    {
        name: "My Profile",
        path: "/myprofile"
    },
    // {
    //     name: "My Courses",
    //     path: "/mycourses"
    // },
];

export const SlideIn = ({ toggle, setToggle }) => {
    const { isLoggedIn } = useAuth();
    const userName = localStorage.getItem("userName");

    const handleToggle = () => {
        setToggle(!toggle);
    };

    return (
        <div className='flex flex-col bg-[#CCFBF1] h-full w-full relative'>
            <div className='p-5 bg-[#EAF8F0]'>
                {!isLoggedIn ? (
                    <div className='flex justify-center'>
                        <div className="bg-[#FFCC00] text-black rounded-full px-6 py-2 border-4 border-[#eac73a] shadow-lg flex items-center">
                            <NavLink
                                className='flex gap-3 py-2 px-4'
                                to="/register"
                                activeClassName="text-blue"
                                onClick={handleToggle}
                            >
                                <span className='text-xl font-semibold'>
                                    Login/Sign up
                                </span>
                            </NavLink>
                        </div>
                    </div>
                ) : (
                    <div className='text-xl text-left'>
                        <h2 className='font-light pb-6'>Hey {userName || "User"}</h2>
                        <ul className='flex flex-col gap-4'>
                            {dropDown.map((data, index) => (
                                <li key={index} className='pl-2'>
                                    <NavLink
                                        to={data.path}
                                        className="flex justify-start items-center gap-4"
                                        onClick={handleToggle}
                                    >
                                        <div className='w-4 h-3 flex items-center'>
                                            <img src={images.DropDown} alt="dropdown" />
                                        </div>
                                        <div className='font-semibold'>{data.name}</div>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className={`pt-6 ${isLoggedIn ? "px-14" : "px-7"}`}>
                <ul className='flex gap-2 flex-col'>
                    {Menus.map((menu, i) => (
                        <li key={i}>
                            <NavLink
                                className='flex gap-3 py-2 px-2'
                                to={menu.path}
                                onClick={handleToggle}
                            >
                                <span className='text-xl font-semibold'>
                                    {menu.name}
                                </span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>

            <div className='bg-[#5960FF] w-full fixed bottom-0 px-10 py-4 text-left text-white rounded-t-lg md:hidden'>
                <h2 className='text-3xl font-bold'>Still Confused?</h2>
                <p className='text-base font-normal'>Our expert can answer all your questions.</p>

                <div className="bg-[#FFCC00] text-black rounded-full px-4 my-2 border-4 shadow-lg flex items-center justify-center whitespace-nowrap">
                    <a href="tel:+91 9876543210" className="flex w-full items-center justify-center">
                        <div className='w-8 h-8'>
                            <img src={images.getCall} alt="getcall" />
                        </div>
                        <span className='text-xl sm:text-sm font-semibold'>
                            Get a Call from us
                        </span>
                    </a>
                </div>

                <p className='text-base font-normal'> or Call us at <a href="tel:+91 9972148403">+91 9972148403</a></p>
            </div>
        </div>
    );
};
