import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.css';
import { images } from '../../Constants';
import { useAuth } from '../../AuthProvider/AuthProvider';
import { SlideIn } from './SlideIn/SlideIn';

export const Navbar = () => {
    const { isLoggedIn, setIsLoggedIn } = useAuth();
    const userImg = localStorage.getItem("userImg");

    const [isMobile, setIsMobile] = useState(false);
    const [active, setActive] = useState(0);
    const [toggle, setToggle] = useState(false);

    const Menus = [
        { name: "Home", path: "/" },
        { name: "About", path: "/about" },
        { name: "Courses", path: "/courses" },
    ];

    const location = useLocation();
    const path = location.pathname;

    const handleClick = (index) => {
        setActive(index);
    };

    const handleToggle = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        const email = localStorage.getItem("email");
        if (email) {
            setIsLoggedIn(true);
        }
    }, [setIsLoggedIn]);

    useEffect(() => {
        if (toggle) {
            document.body.style.overflow = 'hidden'; // Disable scroll
        } else {
            document.body.style.overflow = 'auto'; // Enable scroll
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [toggle]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <nav className={`bg-[#CCFBF1] py-3 px-5 flex items-center justify-between text-gray-800 shadow-md ${toggle || path === "/user" ? "fixed w-full bg-[#CCFBF1]" : "relative"}`}>
            <NavLink to="/" className="flex items-center gap-2 md:gap-4">
                <div className='logo w-12 h-12 md:w-16 md:h-16'>
                    <img src={images.logo} alt='Beyondscool' />
                </div>
                <div className='websitename flex items-center font-inter italic font-extrabold text-2xl lg:text-4xl'>
                    Beyondscool
                </div>
            </NavLink>
            {isMobile ? (
                <div className="relative">
                    <div className="text-3xl cursor-pointer" onClick={handleToggle}>
                        {!toggle ? <i className="fa-solid fa-bars"></i> : <i className="fa-solid fa-xmark"></i>}
                    </div>
                    {toggle && (
                        <div className='fixed top-0 left-0 w-full h-full bg-opacity-50 bg-gray-800 flex justify-center items-center z-50'>
                            <SlideIn toggle={toggle} setToggle={setToggle} />
                        </div>
                    )}
                </div>
            ) : (
                <div className='flex items-center space-x-8'>
                    <ul className='flex gap-6'>
                        {Menus.map((menu, i) => (
                            <li key={i}>
                                <NavLink
                                    className={`text-xl font-semibold py-2 px-4 rounded-lg transition-colors duration-300 ${path === menu.path ? "bg-[#EAF8F0] text-blue-600" : "hover:bg-[#EAF8F0] hover:text-blue-600"}`}
                                    to={menu.path}
                                    onClick={() => handleClick(i)}
                                >
                                    {menu.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <div className='flex items-center'>
                        {!isLoggedIn ? (
                            <div className="bg-[#FFCC00] text-black rounded-full px-4 py-2 border-2 border-white shadow-lg flex items-center">
                                <NavLink
                                    className="text-xl font-semibold"
                                    to="/register"
                                >
                                    Login/Sign up
                                </NavLink>
                            </div>
                        ) : (
                            <NavLink to="/myprofile" className='w-12 h-12'>
                                <img className='rounded-full w-full h-full object-cover' src={userImg} alt="user" />
                            </NavLink>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};
